@if (url) {
  <div class="avatar">
    <div
      class="w-24 rounded-full shadow-lg ring-primary ring-1 ring-offset-0"
      [ngStyle]="{ height: sizeInPx + 'px', width: sizeInPx + 'px' }"
    >
      <img [src]="url" alt="Profil Bild" (error)="url = undefined" />
    </div>
  </div>
} @else {
  <div>
    <i class="far fa-user text-gray-400" style="font-size:{{ sizeInPx }}px"></i>
  </div>
}
