import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "smallstack-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule]
})
export class AvatarComponent {
  @Input()
  public url: string;

  protected sizeInPx: number = 64;

  @Input()
  public set size(size: "xs" | "sm" | "md" | "lg" | "xl") {
    switch (size) {
      case "xl":
        this.sizeInPx = 128;
        break;
      case "lg":
        this.sizeInPx = 96;
        break;
      case "sm":
        this.sizeInPx = 32;
        break;
      case "xs":
        this.sizeInPx = 24;
        break;
      case "md":
      default:
        this.sizeInPx = 64;
    }
  }
}
